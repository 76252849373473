import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import loadable from '@loadable/component'
import Icon from '../../svg/icon-plus.svg'

const PortableText = loadable(() => import('../PortableText'))

const FAQ = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)
  const rotateClass = isOpen ? 'rotate-45' : 'rotate-0'

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="col-span-12 w-full md:max-w-7/12 mb-4 bg-white rounded-4 mx-auto">
      <button
        className="flex w-full py-2 px-4 text-sm md:text-base text-gray-800 text-left leading-tight items-start justify-between"
        type="button"
        onClick={handleClick}
      >
        <span className="mt-2px">{question}</span>
        <span
          className={`mt-2px ml-2 text-blue-500 transform transition-transform duration-200 ${rotateClass}`}
        >
          <Icon className="w-18px h-18px -mt-2px md:mt-0" />
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
            className="overflow-hidden w-full"
          >
            <motion.div
              className="p-4 text-left shadow-top"
              variants={{
                collapsed: {
                  opacity: 0,
                },
                open: {
                  opacity: 1,
                },
              }}
              transition={{ duration: 0.2, delay: 0.1 }}
            >
              <PortableText blocks={answer} isBasic />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default FAQ

FAQ.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.array,
}
